@use '../../scss/abstracts/' as *;

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100vw !important;
    height: 100% !important;
    background: rgba(0, 0, 0, 0.6);
    z-index: 4666;
  }
  
  .modal-main {
    position:fixed;
    background: $bodyBg;
    width: 60%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    transition: all 0.5s;
    @include rounded-border;
    @include padded-box;

    @include for-tablet-portrait-up{
        width: 95%;
    }

    &__header{
        height: 3rem;
        @include thin-border-bottom;

        i{
            color: $color-grey-100;
            font-size: $font-18;
            margin-bottom: 1rem;
        }
    }
    &__body{
        height: auto;
        max-height: 30rem;
        overflow: auto;
        overflow-x: scroll;
    }
    &__footer{
        height: 3rem;
    }
  }
  
  .display-block {
      display: block;
    @include for-tablet-portrait-up{
        display: flex !important;
        align-content: center;
        justify-content: center;
    }
  }
  
  .display-none {
    display: none;
  }