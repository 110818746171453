@use  '../../scss/abstracts/' as *;

.sidebar {
    height: 100%;
    position: fixed;
    top: 20;
    left: 0;
    width: 250px;
    bottom: 0 !important;
    background: $primary;
    transition: left 0.5s ease;
    -webkit-transition: left 0.5s ease;
    -moz-transition: left 0.5s ease;
    -ms-transition: left 0.5s ease;
    -o-transition: left 0.5s ease;
    z-index: 1000;
    display: none;

    @include for-phone-only {
        display: block;
        width: 75% !important;
    }
    @include for-tablet-landscape-up {
        display: block;
        width: 50%;
    }
  }
  .sidebar--closed{
    display: none;
  }
  .sidebar ul {
    list-style: none;
    margin-top: 2rem;
    margin-left: 2rem;
    height: 100%;
  }
  
  .sidebar ul li {
    margin-top: 1rem;
    padding: 0.3rem;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }
  .link i{
      margin-right: 1rem;
      color: $white;
  }
  
  .sidebar ul li.active {
    background: var(--primary-color);
    border-bottom-left-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
    transition: background 0.5s linear;
    box-shadow: rgba(20, 114, 238, 0.918) 0px 2px 10px 0px;
  }
  
  .sidebar li .icon {
    margin-right: 1rem;
    padding: 0.5rem;
  }
  .sidebar li a {
    text-decoration: none;
    font-size: $font-11;
    color: $white;
    display: flex;
    align-items: center;
  }
  .sidebar li a:hover {
    margin-left: 2px;
  }
  
  .close-icon {
    color: white;
    position: absolute;
    top: .1%;
    right: 0;
    font-size: $font-16;
    margin: 1rem;
    cursor: pointer;
  }
  
  .hamburger-menu {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    font-size: 3rem;
    color: #006ce0;
    cursor: pointer;
  }
  
  body .toggle.sidebar {
    left: -270px;
  }
  body.toggle.section {
    margin-left: 0;
    width: 100%;
  }
  .sidebar-logo{
    margin-top: 20%;
    display: flex;
    align-content: center;
    justify-content: center;
    border-bottom: 1px solid $secondary;
    img{
      width: 75%;
      margin-bottom: 3rem;
    }
  }