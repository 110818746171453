@use "../scss/abstracts/" as *;

.main{
    background: $bodyBg;
    width: 100vw;
    height: 100vh;
    position: relative;

    &__content{
        background: $bodyBg;
        width: 100%;
        min-height: 100%;
        background: linear-gradient(to right bottom, 
        rgba(255, 255, 255, 0.7), 
        rgba(255, 255, 255, 0.3));
        z-index: 2;
        backdrop-filter: blur(3rem);
    }
}