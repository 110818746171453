@use '../../scss/abstracts/' as *;

.topnav{    
    background: white;
    height: 8rem;
    position: sticky;
    top: 0;
    z-index: 100;
    
    &__topbar{
        padding-left: 1rem;
        padding-right: 1rem;
        height: 70%;
        border-bottom: 1px solid $secondary;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        @include for-phone-only{
            justify-content: space-between;
            height: 60%;
        }

        img{
            width: 10rem;
            margin-right: 1rem;
            margin-left: 2rem;
            @include for-tablet-portrait-up {
               width: 9rem;
               margin-left: 0rem;
            }
        }

        &__cart{
            // display: inline-block; /* Inline elements with width and height. TL;DR they make the icon buttons stack from left-to-right instead of top-to-bottom */
            position: relative; /* All 'absolute'ly positioned elements are relative to this one */
            padding: 2px 5px; 
            
            display: flex;
            align-items: center;
            margin-left: 1rem;
            font-weight: bold;
            font-size: $font-12;

            i{
                font-weight: bold;
                font-size: $font-14;
            }
            
            span{
                color: $white;
                background-color: $secondary;
                border-radius: 2px;                
                padding: 1px 5px;
                font-size: 10px;                
                position: absolute; /* Position the badge within the relatively positioned button */
                top: -27%;
                right: 0;
            }
        }
    }
    /* header search */
    &__search{
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;

        @include for-phone-only{
            display: none;
        }

        /* header search Input */
        &__searchInput{
            height: 2.5rem;
            padding: 0.8rem;
            border: 1px solid $secondary;
            width: 75%;
            border-bottom-right-radius: 0rem;
            border-top-right-radius: 0rem;
            border-bottom-left-radius: .4rem;
            border-top-left-radius: .4rem;
            font-size: $font-10;     
            @include for-phone-only{
                font-size: .8rem;
            }       
        }
        input:focus{
            outline: none;
        }

         /* header search Icon */
        i{
            padding: 0.8rem;
            height: 2.5rem !important;
            background-color: $secondary;
            border-bottom-right-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
            color: $white;
            text-align: center;
            cursor: pointer;
        }
    }

    .topnav__profile{
        display: flex;
        align-items: center;
        font-size: .9rem;

        img{
            @include circle-shape;
            @include box-shadow;
            width: 2rem;
            height: 2rem;
            margin-left: 1rem;
            border: 1px solid $secondary;
        }
    }

    // lower bar
    &__lowerbar{
        background-color: $primary;
        height: 30%;
        padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $white;
        @include box-shadow-bottom;

        @include for-phone-only{
            height: 40%;
        }

        &__icons{
            display: flex;
            font-weight: bold;
            color: $white;
            font-size: $font-15;
            margin-right: 1rem;
            align-items: center;
            i{
                margin-top: .1rem;
                font-size: $font-15;
            }
        }
        span{
            margin-left: 1rem;
        }
        
    }
}
.product-search{
    display: none;
    @include for-phone-only{
        display: flex;

         /* header search Input */
         input{
            height: 1.8rem;
            width: 75%;         
        }
         /* header search Icon */
        i{
            padding: 0.5rem;
            height: 1.8rem !important;
        }
    }
}

.main_menu{
    display: flex;
    font-size: .9rem;
    font-weight: bold;
    @include for-tablet-portrait-up{
        display: none;
    }
     @include for-tablet-landscape-up{
        display: none;
    }
    &__item{
        cursor: pointer;
    }
}

.logout{
    font-size: $font-10;
    cursor: pointer;
}
.auth-text{
    font-size: $font-10;
    margin-left: 1rem;
    @include for-phone-only {
        display: none !important;
    }
}
.top-section{
    height: 30px;
    width: '100%';
    padding-left: 40px;
    display: flex;
}
.hide-on-phone{
        @include for-phone-only{
            display: none;
        }
        
    }