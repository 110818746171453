@use "../../scss/abstracts/" as *;

.product-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 75vh;
    width: 100%;
    padding: 0 1rem;

    &__breadcrumb{
        color: $color-grey;
        font-size: $font-10;
        display: flex;
        align-items: center;
        width: 100%;
        height: 2rem;
    }
    &__product-details{
        display: flex;
        width: 100%;
        height: 100%;

        @include for-tablet-portrait-up{
            display: block;
            height: auto;
        }
        
        &__product{
            display: flex;
            width: 100%;
            height: inherit;
            margin-right: 1rem;
            // background: $white;
            // @include rounded-border;
            // @include box-shadow;

            @include for-phone-only{
                display: block;
            }

            @include for-tablet-portrait-up{
                margin-bottom: 1rem;
            }

            &__image{
                width: 40%;
                height: auto;
                padding: 1rem;

                @include for-phone-only{
                    width: 100% !important;
                }
    
                img{
                    width: 100%;
                    height: 100%;
                    object-position: top;
                    object-fit: contain;
                    @include rounded-border;
                }
            }
            &__info{
                width: 60%;
                height: auto;
                padding: 1rem;

                @include for-phone-only{
                    width: 100% !important;
                }
    
                p{
                    color: $black;
                    font-size: $font-10;
                }
                &__quantity{
                    display: flex;
                    align-content: center;
                    i{
                        font-size: $font-15;
                        margin: .5rem;
                        cursor: pointer;
                    }
                    input{
                        width: 4rem;
                        text-align: center;
                    }
                }
                &__promotions{
                    margin-top: 1rem;
                }
            }
        }

        &__other{
            width: 40%;
            height: 100%;
            background: $white;
            padding: 1rem;
            @include rounded-border;
            @include box-shadow;

            @include for-tablet-portrait-up{
                width: 100%;
            }

            &__item{
                display: flex;

                &__icon{
                    margin-right: 1rem;
                    height: 2.5rem;
                    width: 2.5rem;
                    background: $lightgrey;
                    color: $txtGrey;
                    padding: 1rem;
                    display: flex;
                    align-items: center;
                    @include circle-shape;
                }
            }
        }
    }
}