@use '../../scss/abstracts/' as *;

$field-container-padding: 5px;
$label-padding: $field-container-padding !default;


.field-container{
  position: relative;
  height:50px;
  width:100%;
  padding-left:10px;
  margin-bottom: 2.5rem;
  border: 1px solid $grey;
  @include rounded-border;
}

.field-container p{
  margin-top: .5rem;
  color: $red;
}

.field-placeholder{
  position: absolute;
  background-color: $white;
  top: 75%;
  transform: translate(#{$label-padding}, -100%);
  pointer-events: none;
  transition: all .14s ease-in-out;
  font-size:.9rem;
  color:$color-grey-100;
}

.field-input
{
  color: #000;
 border:none;
  padding: 10px;
  margin-top:6px;
  font-size: .9rem;
  display: block;
  box-sizing: border-box;
  width: 100%;
  bottom:0px;
  background-color: $white;

  &:focus {
    outline: none;
  }

  &.c-fix,
  &:focus,
  &:not(:placeholder-shown)
  {
    border-color: transparent;
    ~ label {
     color: $color-grey-200;
      font-size: .9rem;
      //Come up with a calculation for this
      top: calc(-36%);
      transform: translate(#{$label-padding}, 0%);
      padding: 5px;
    }
  }
  
  &::-webkit-input-placeholder {
    color: transparent;
  }
  &::-moz-placeholder {
    color: transparent;
  }
  &:-ms-input-placeholder {
    color: transparent;
  }
 
}

