@use './abstracts/' as *;
@use 'sass:math';

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 math.div($spacing, 2) * -1;

    @include for-tablet-portrait-up {
        margin: 0 math.div($mobileSpacing, 2) * -1;
    }
}

[class*="col-"] {
    padding: 0 math.div($spacing, 2);

    @include for-tablet-portrait-up {
        padding: 0 math.div($mobileSpacing, 2);
    }
}

@for $i from 1 through 12 {
    $v: math.div($i, 12) * 100;
    .col-#{$i} {
        width: $v*1%;
    }
}

// medium screen

@include for-tablet-portrait-up {
    @for $i from 1 through 12 {
        $v: math.div($i, 12) * 100;
        .col-md-#{$i} {
            width: $v*1%;
        }
    }

    .hide-md {
        display: none;
    }
}

// small screen

@include mobile {
    @for $i from 1 through 12 {
        $v: math.div($i, 12) * 100;
        .col-sm-#{$i} {
            width: $v*1%;
        }
    }

    .hide-sm {
        display: none;
    }
}

.d-flex{
    display: flex;
    @include for-phone-only {
        display: block
      }
}
  
.cards {
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
//   z-index: 999;
}

.card {   
    display: flex;
    align-items: center;
    justify-content: space-between; 
    background-color: $bodyBg;
    color: $color-grey-200;
    padding: 1rem;
    min-height: 10rem;
    @include rounded-border;
    @include box-shadow;

    p{
        font-size: $font-10;
        color: $black;
    }

    span{
        display: block;
    }

    i{
        font-weight: bold;
        font-size: $font-20;
    }
  }
  .card:hover{
    background-color: $primary;
    color: $secondary;

    p{
        color: $white;
    }
  }

//   mobile phones and tablets sow two columns
  @include for-tablet-portrait-up {
    .cards { grid-template-columns: repeat(2, 1fr); }
  }

.flex-row{
    display: flex;
}
.justify-between{
    justify-content: space-between;
}

.justify-start{
    justify-content: flex-start;
}

.justify-end{
    justify-content: flex-end;
}

.justify-center{
    justify-content: center;
}