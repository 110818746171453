@use '../../scss/abstracts/' as *;
@import url(https://fonts.googleapis.com/css?family=Quantico);

.textArea-input-holder {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.textArea-input-field {
  font-family: inherit;
  width: 100%;
  font-size: .9rem;
  padding: 1.1rem 1.3rem;
  background: transparent;
  transition: border-color 0.2s;
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 1em 0;
  border: 1px solid $grey;
  @include rounded-border;
  resize: none;
  outline: none;
}

.textArea-input-field::placeholder {
  color: transparent;
}

.textArea-input-field:placeholder-shown ~ .textArea-input-label {
  font-size: 16px;
  cursor: text;
  top: 20px;
}
.textArea-input-label {
  position: absolute;
  top: 1px;
  left: 15px;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $color-grey-200;
  background-color: $white;
  padding: 0 7px;
}

.textArea-input-field:focus ~ .textArea-input-label {
  color: $color-grey-100;
}

.textArea-input-field:focus {
  padding: 1.1rem 1.3rem;
  border: 1px solid $color-grey-200;
}

.textArea-input-field:valid {
    border-color: $color-grey-100;
}

.textArea-input-label {
  color: $color-grey-100;
}