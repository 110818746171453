@use './abstracts/' as *;

.col-12{
    width: 100%;
}

.col-10{
    width: 90%;
    @include for-tablet-portrait-up {
        width: 100% !important;
    }
}
.col-9{
    width: 80%;
    @include for-tablet-portrait-up {
        width: 100% !important;
    }
}
.col-8{
    width: 70%;
    @include for-tablet-portrait-up {
        width: 100% !important;
    }
}
.col-7{
    width: 60%;
    @include for-tablet-portrait-up {
        width: 100% !important;
    }
}

.col-6{
    width: 50%;
    @include for-tablet-portrait-up {
        width: 100%;
    }
    @include for-phone-only {
        width: 100vw !important;
    }
}

.col-5{
    width: 40%;
    @include for-tablet-portrait-up {
        width: 100% !important;
    }
}
.col-4{
    width: 30%;
    @include for-tablet-portrait-up {
        width: 100% !important;
    }
}
.col-3{
    width: 20%;
    @include for-tablet-portrait-up {
        width: 100% !important;
    }
}
.col-2{
    width: 10%;
    @include for-tablet-portrait-up {
        width: 100% !important;
    }
}

