@use '../../scss/abstracts/' as *;

.cart-items{
    width: 100%;
    padding: 1rem 5rem;
    height: auto;
    margin-top: 1rem;
    @include thin-border;

    @include for-tablet-portrait-up {
        padding: 1rem;
    }
}

/* Global settings */
$color-border: #eee;
$color-label: #aaa;
$font-default: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-bold: 'HelveticaNeue-Medium', 'Helvetica Neue Medium';


/* Global "table" column settings */
.product-image { float: left; width: 20%; }
.product-details { float: left; width: 30%; }
.product-price { float: left; width: 15%; }
.product-quantity { float: left; width: 15%; }
.product-removal { float: left; width: 5%; }
.product-line-price { float: left; width: 15%; text-align: right;}


/* This is used as the traditional .clearfix class */
.group:before,
.group:after {
    content: '';
    display: table;
} 
.group:after {
    clear: both;
}
.group {
    zoom: 1;
}


/* Apply clearfix in a few places */
.shopping-cart, .column-labels, .product, .totals-item {
  @extend .group;
}


/* Apply dollar signs */
.product .product-price:before, .product .product-line-price:before, .totals-value:before {
  content: 'Kshs.';
}

/* Column headers */
.column-labels {
  label {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $color-border;
    font-weight: bold;
  }

}


/* Product entries */
.product {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid $grey;
  
  .product-image {
    // text-align: center;
    img {
      width: 100px;
    }
  }
  
  .product-details {
    .product-title {
      margin-right: 20px;
      font-weight: bold;
    }
    .product-description {
      margin: 5px 20px 5px 0;
      line-height: 1.4em;
      font-size: .7rem;
    }
  }
  
  .product-quantity {
    input {
      width: 30px;
      height: 30px;
    }
    i{
      margin-top: 3%;
    }
  }
  
  .remove-product {
    border: 0;
    padding: 4px 8px;
    background-color: $red;
    color: $white;
    font-size: 12px;
    border-radius: 3px;
  }
  
  .remove-product:hover {
    background-color: $red;
  }
  .product-line-price {
     font-weight: bold;
   }
}


/* Totals section */
.totals {
    width: 100%;
    &__item{
        display: flex;
        float: right;

        label{
            margin-right: 3rem;
        }
    }
    &__value{
        font-weight: bold;
        border-bottom: 1px solid $grey;
        ::before{
            content: 'Kshs.';
        }
    }
    &__value::before{
        content: 'Kshs.';
    }
}

.checkout {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button{
        border: 0;
        margin-top: 1rem;
        padding: 6px 25px;
        background-color: $color-green;
        color: $white;
        font-size: 18px;
        border-radius: 5px;
        cursor: pointer;
    }
    button:hover {
        background-color: $color-green-dark;
      }
  
}

/* Make adjustments for tablet */
@media screen and (max-width: 650px) {
  
  .shopping-cart {
    margin: 0;
    padding-top: 20px;
    border-top: 1px solid $color-border;
  }
  
  .column-labels {
    display: none;
  }
  
  .product-image {
    float: right;
    width: auto;
    img {
      margin: 0 0 10px 10px;
      padding-top: 2.3rem;
    }
  }
  
  .product-details {
    float: none;
    margin-bottom: 10px;
    width: 100%;
    min-height: 9rem;
  }
  
  .product-price {
    clear: both;
    width: 30%;
  }
  
  .product-quantity {
    width: 30%;
    display: flex;
  }
  
  .product-quantity:before {
    content: 'x';
    margin-left: -20px;
    margin-right: 1rem;
  }
  
  .product-removal {
    width: 30%;
  }
  
  .product-line-price {
   display: flex;
   justify-content: flex-end;
    width: 100%;
  }
  
}