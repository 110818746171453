@use './abstracts/' as *;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html, body {
    height: 100%;
    min-height: 100%;
}

html {
    overflow-x: hidden;
    overflow: hidden;
}
body {
    font-family: $fontFamily;
    color: $txtColor;
    font-size: $fontSizeNormal;
    line-height: 1.5;
    background-color: $bodyBg;
    overflow-x: hidden;
    min-height: 100%;
    height: 100%;
    position: relative;
}

a {
    text-decoration: none;
    color: unset;
}

img {
    width: 100%;
}

ul,
li {
    list-style-type: none;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: transparent;
}

.mb{
    margin-bottom: $spacing;

    @include for-tablet-portrait-up {
        margin-bottom: $mobileSpacing;
    }
}
.mt{
    margin-top: $spacing;

    @include for-tablet-portrait-up {
        margin-top: $mobileSpacing;
    }
}

hr{
    border: 0;
    height: 1px;
    width: 100%;
    margin: 1rem 0;
    background: #333;
    background-image: linear-gradient(to right, #ccc, rgb(209, 206, 206), #ccc);
}
.breadcrumb-separator{
    color: $black;
    font-size: .6rem !important;
    margin: 0 .5rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.small-text{
    font-size: .9rem;
}

.title{
    font-size: $fontSizeMedium;
    font-weight: 700;
}

.text-upper{
    text-transform: uppercase;
}
.text-lower{
    text-transform: lowercase;
}

.title-small{
    font-size: $fontSizeSmall;
    font-weight: 700;
}

.title-large{
    font-size: $fontSizeExtraLarge;
    font-weight: 700;
}

.text-info{
    color: $blue;
}
.text-success{
    color: $txtGreen;
}
.text-danger{
    color: $txtRed;
}
.text-white{
    color: $white;
}
.text-primary{
    color: $primary !important;
}
.text-secondary{
    color: $secondary!important;
}

.clearfix{
    height: 25px;
    width: 100%;
}

.links{
    color: $blue;
    cursor: pointer;
}

.sideModal-overlay{
    z-index: 1000;
    background-color: rgb(0, 0, 0 );
}

.sideModal{
    @include for-tablet-portrait-up {
        width: 100vw !important;
    }
}
.form{
    width: 100% !important;
    display: block;
    align-items: flex-start;
}
.font-7{
    font-size: $font-7;
}
.font-8{
    font-size: $font-8;
}
.font-9{
    font-size: $font-9;
}
.font-10{
    font-size: $font-10;
}
.font-12{
    font-size: $font-12;
}
.font-13{
    font-size: $font-13;
}
.font-14{
    font-size: $font-14;
}
.font-15{
    font-size: $font-15;
}
.font-16{
    font-size: $font-16;
}
.font-18{
    font-size: $font-18;
}

.font-20{
    font-size: $font-20 !important;
}
.font-25{
    font-size: $font-25 !important;
}

.font-30{
    font-size: $font-30;
}
.div-wrapper{
    @include rounded-border;
    @include margin-around;
    @include box-shadow ;
    @include padded-box ;
    background-color: $mainBg !important;
    height: auto;
}

.box-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    i{
        font-size: $font-15;
    }
}

.boxed-div{
    width: 100%;
    height: fit-content;
    border: 1px solid $lightgrey;
    @include rounded-border;
    padding: .5rem;
}
.flexy{
    display: flex;
    justify-content: stretch ;

    @include for-tablet-portrait-up {
        display: block;
    }
}
.responsive-img{
    width: 100%;
    height: auto;
    max-height: 250px;
    margin-right: 2rem;
    border-radius: .7rem;
    border: 1px solid $lightgrey;

    @include for-tablet-portrait-up {
        width: 100% !important;
        object-fit: cover;
        margin-bottom: 2rem;
        height: auto;
        max-height: 500px;
    }
}

.row{
    display: flex;
    justify-content: space-between ;

    @include for-tablet-portrait-up {
        display: block;
    }
}
.absolute{
    position: absolute;
}

// with margin right only
.mr-5{
    margin-right: .5rem !important;
}
.mr-10{
    margin-right: 1rem !important;
}
.mr-15{
    margin-right: 1.5rem !important;
}
.mr-20{
    margin-right: 2rem !important;
}

// with margin left only
.ml-5{
    margin-left: .5rem !important;
}
.ml-10{
    margin-left: 1rem !important;
}
.ml-15{
    margin-left: 1.5rem !important;
}
.ml-20{
    margin-left: 2rem !important;
}

// with margin right and left
.mx-5{
    margin: 0rem .5rem;
}
.mx-10{
    margin:0rem  1rem;
}
.mx-15{
    margin: 0rem 1.5rem;
}
.mx-20{
    margin:0rem  2rem;
}

// with margin top and bottom
.my-5{
    margin: .5rem 0rem;
}
.my-10{
    margin: 1rem 0rem;
}
.my-15{
    margin: 1.5rem 0rem;
}
.my-20{
    margin: 2rem 0rem;
}

// with margin top
.mt-5{
    margin-top: .5rem;
}
.mt-10{
    margin-top: 1rem;
}
.mt-15{
    margin-top: 1.5rem;
}
.mt-20{
    margin-top: 2rem;
}

// with margin bottom
.mb-5{
    margin-bottom: .5rem;
}
.mb-10{
    margin-bottom: 1rem;
}
.mb-15{
    margin-bottom: 1.5rem;
}
.mb-20{
    margin-bottom: 2rem;
}


// with margin all round
.m-5{
    margin: .5rem;
}
.m-10{
    margin: 1rem;
}
.m-15{
    margin: 1.5rem;
}
.m-20{
    margin: 2rem;
}

.mobile-nav{
    display: none !important;
    @include for-tablet-landscape-up {
        display: flex !important;
    }
}
.row-items{
    @include flex(stretch, flex-start);
    padding: 1rem;

    &__item{
        background-color: $white;
        width: 6rem;
        height: 4rem;
        margin: .1rem 1rem;
        @include box-shadow;
        @include rounded-border;
        padding: .5rem;
    }
}

.w-full{
    width: 100%;
}

.w-half{
    width: 50% !important;
    @include for-phone-only{
        width: 100% !important;
        margin:  1rem 0 !important;
    }
}

// backgrounds
.bg-primary{
    background-color: $primary !important;
    color: $white !important;
}
.bg-red{
    background-color: $red !important;
    color: $white !important;
}
.bg-blue{
    background-color: $blue !important;
    color: $white !important;
}
.bg-secondary{
    background-color: $secondary!important;
    color: $secondary !important;
}

.items-center{
    align-items: center;
}

.space-x-2{
    padding-right: 20px;
}

// SWIPER CSS
.swiper-container{
    height: 20rem !important;
    width: 50rem !important;
}
.swiper {
  width: 100%;
  height: 100%;
}


.swiper-slide {
  text-align: left;
  font-size: $font-10;
  background: white;
  @include rounded-border;
  @include box-shadow;
  border: 1px solid rgba(0,0,0,0.008);
  

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category-container{
    &__header{
        display: flex;
        justify-content: space-between;

        i{
            color: $txtGrey;
            margin: .5rem;
            cursor: pointer;
        }
    }
}

.dawa-prompt{
    @include thin-border;
    @include box-shadow;
    @include padded-box;
    @include rounded-border;
    width: 30rem;
    height: auto;
    min-height: 10rem;
    background-color: $color-red-100;
    @include for-phone-only {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
        width: 20rem;
        h1{
            font-size: $font-10 !important;
        }
    }

    h1,h2,h3{
        color: $color-grey;
    }
    h1{
        font-size: $font-15;
    }
    button{
        min-width: 5rem;
    }
    &__header{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 1rem;
    }
}
button:disabled{
    background-color: $color-grey-100 !important;
    cursor: not-allowed !important;
}

.display-flex{
    display: flex !important;
    width: 100% !important;    
}
.space-between{
    justify-content: space-between;
}

.space-around{
    justify-content: space-around;
}
.space-evenly{
    justify-content: space-evenly;
}

.hidden{
    display: none;
}
.mobile-only{
    display: none !important;
    @include for-phone-only {
        display: block !important;
    }
}
.tablet-only{
    display: none;
    @include for-phone-only {
        display: none !important;
    }
    @include for-tablet-portrait-up{
        display: block;
    }
}
.hideScrolling{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}