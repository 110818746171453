@use '../../scss/abstracts/' as *;

.btn {
  position: relative;
  overflow: hidden;
  padding: .7rem 1.2rem;
  font-size: 1rem;
  font-weight: 600;
  outline: 0;
  border: 0;
  cursor: pointer;
  width: fit-content;
  border-radius: $borderRadius;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.btn-success{
    background-color: $color-green;
    color: $white;
}

.btn-danger{
    background-color: $red;
    color: $white;
}

.btn-info{
  background-color: $blue;
  color: $white;
}

.btn-primary{
  background-color: $primary;
  color: $white;
}
.btn-secondary{
  background-color: $secondary;
  color: $black;
}