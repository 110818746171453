@use '../../scss/abstracts/' as *;

.product-wrapper{
    &__category-title{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .5rem 0;
        height: 1rem;

        i{
            font-size: .8rem;
            margin-left: .7rem;
        }
        
        @include for-tablet-portrait-up {
            margin-bottom: 2rem;
            margin-top: -.1rem;
    
            h2{
                font-size: 1.4rem !important;
            }
        }
    }
    &__display{
        display: flex;

        &__filters{
            width: 30%;
            padding: 1rem;

            @include for-tablet-portrait-up {
                display: none;
            }

            &__option{
                margin: 1rem;
            }
            &__categories{
                margin: 1rem;

                &__item{
                    margin-bottom: .5rem;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }

                p{
                    color: $white;
                    margin-left: .3rem;
                    padding: .1rem .4rem;
                    font-size: .9rem;
                    background: $blue;
                    @include rounded-border;
                }
            }
        }
        &__products{
            width: 50%;
            padding: 1rem;

            @include for-tablet-portrait-up {
                width: 100%;
                padding: 0;
            }

            &__item{
                display: flex;
                height: 9rem;
                width: 100%;
                padding: .5rem;
                background: inherit;
                @include box-shadow-bottom;
                @include thin-border;
                margin-bottom: 1.5rem;

                &__image{
                    width: 8rem;
                    height: 100%;
                    margin-right: 1.5rem;
                    overflow:hidden;
                    position:relative;
                    
                    img{
                        position: absolute; 
                        top:0; right: 0; 
                        left: 0; 
                        bottom: 0; 
                        width: 100%; 
                        height: 100%; 
                        object-fit: contain;
                    }
                }
                &__details{
                    width: 100%;
                    &__footer{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }
        }
        &__other{
            width: 20%;
            padding: 1rem;
            
            @include for-tablet-portrait-up {
                display: none;
            }
        }
    }
}