@use "../../scss/abstracts/" as *;

.no-items-found{
    width: 200px;
    height: 200px;
    align-items: center;
    display: flex;
    flex-direction: column;
    
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;

    img{
        width: 20rem;
        margin-bottom: 1rem;

        @include for-phone-only{
            width: 15rem;
        }
    }
}