@use "../../scss/abstracts/" as *;

.auth{
    width: 100%;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: inherit;   

    @include for-tablet-portrait-up{
        height: 100%;
    }

    &__box{
        width: 25rem;
        min-height: 30rem;
        background-color: $white;        
        @include rounded-border;
        @include box-shadow;
        @include padded-box;

        @include for-tablet-portrait-up{
            margin: 2rem;
        }

        &__header{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 7rem;
            width: 100%;
        }
        &__footer{
            height: 4rem;
            width: 100%;
        }
    }
}