@use  '../../scss/abstracts/' as *;

.section {
    width: 100vw;
    margin: 5rem auto;
    max-width: 1000px;
    /* width: 100%; */
  }  
  
  @media screen and (min-width: 992px) {
    .section {
      width: 95vw;
    }
  }
  
  .section-center {
    height: 25rem;
    text-align: center;
    display: flex;
    overflow: hidden;

    @include for-phone-only{
      height: auto !important;
    }

    @include for-tablet-portrait-up{
      height: 120px;
    }

    @include for-tablet-landscape-up{
      height: 150px;
    }
  }
  
  article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    opacity: i;
    transition: all 0.3s linear;
  }
  
  article.activeSlide {
    opacity: 1;
    transform: translateX(0);
  }
  article.lastSlide {
    transform: translateX(-100%);
  }
  article.nextSlide {
    transform: translateX(100%);
  }
  
  .banner-img {
    margin-top: 8rem;
    width: 100%;
    z-index: 0;
    margin-bottom: -140px;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }
  
  .prev,
  .next {
    position: absolute;
    top: 25rem;
    margin-left: 10px;
    color: black;
    width: 1.5rem;
    height: 1.5rem;
    display: grid;
    place-items: center;
    cursor: pointer;
    font-size: $font-20;

    @include for-phone-only{
      top: 12rem !important;
    }
    
    @include for-tablet-portrait-up{
      top: 15rem;
    }

    @include for-tablet-landscape-up{
      top: 16rem;
    }
  }

  .prev {
    left: 0;
  }
  .next {
    right: 0;
    margin-right: 1rem;
  }
  @media (min-width: 800px) {
    .text {
      max-width: 45em;
    }
    .prev,
    .next {
      width: 2rem;
      height: 2rem;
      font-size: 2.5rem;
    }
  }
  